<template>
  <v-container fluid fill-width ma-0 pa-0 class="body-container">
    <div class="purple-gradient fill-height">
      <Navbar page="provider" />
    </div>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <h1 class="mt-10 text-h1">Recibo</h1>
        <p class="text-subtitle-2">No. de solicitud: {{ requestNumber }}</p>
        <div align="end"></div>
      </v-col>
      <v-col cols="1"></v-col>

      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" md="2">
              <Button
                text="Volver"
                outlined
                pill
                :height="44"
                prepend-icon="fa-caret-left"
                aria-label="Volver a lista de reservaciones"
                url="AdminReservations"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-container class="d-flex flex-column flex-md-row pa-0 ml-md-16">
          <v-container class="mx-md-8 my-3 mx-0 pa-0">
            <v-card class="pa-5 rounded-xl" outlined>
              <h2>Informacion del cliente</h2>
              <br />
              <p v-for="(clientItems, idx) in clientInfo" :key="idx">
                {{ clientItems.data }}
              </p>
            </v-card>
          </v-container>

          <v-container class="mx-md-8 my-3 mx-0 pa-0">
            <v-card class="pa-5 rounded-xl" outlined>
              <h2>Informacion del proveedor</h2>
              <br />
              <p
                v-for="(providerItems, idx) in providerInfo"
                :key="idx"
                :class="`${classStyle[idx]}`"
              >
                {{ providerItems.data }}
              </p>
            </v-card>
          </v-container>
        </v-container>
      </v-col>

      <v-col sm="12">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10" sm="10" md="7">
            <template>
              <h2>Desglose de servicio solicitado</h2>
              <br />
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="py-2 px-0" width="200px">Tipo de servicio</td>
                      <td class="pl-sm-16">
                        {{ serviceType }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-0" width="200px">Costo de servicio</td>
                      <td class="pl-sm-16">
                        {{ numberToCurrency(serviceCost) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-0" width="200px">
                        Cotización de servicio
                      </td>
                      <td class="pl-sm-16">
                        <Button
                          text="Ver"
                          class="my-3"
                          aria-label="Descargar cotización de servicio"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
          <v-col cols="0" sm="4"> </v-col>
        </v-row>
      </v-col>

      <v-col sm="12">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10" sm="10" md="7">
            <template>
              <h2>Desglose de servicio solicitado</h2>
              <br />
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="py-2 px-0" width="200px">
                        Anticipo
                        {{ advancePayment | percent }}
                      </td>
                      <td>{{ numberToCurrency(totalAdvance) }}</td>
                      <td>
                        {{ dateFormater(paymentDetails[0].data) }}
                      </td>
                      <td>
                        {{ paymentDetails[1].data }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        reservationStatus == 'received' ||
                        (reservationStatus == 'canceled' &&
                          paymentDetails.length <= 3)
                      "
                    >
                      <td class="py-2 px-0" width="200px">Liquidacion</td>
                      <td>
                        {{ numberToCurrency(settlementPayment) }}
                      </td>
                      <td v-if="reservationStatus == 'received'">
                        <i>Fecha limite</i>
                        {{ dateFormater(paymentDetails[2].data) }}
                      </td>
                    </tr>
                    <tr
                      v-else-if="
                        reservationStatus == 'concluded' ||
                        (reservationStatus == 'canceled' &&
                          paymentDetails.length > 3)
                      "
                    >
                      <td class="py-2 px-0" width="200px">Liquidacion</td>
                      <td>
                        {{ numberToCurrency(settlementPayment) }}
                      </td>
                      <td>
                        {{ dateFormater(paymentDetails[3].data) }}
                      </td>
                      <td>
                        {{ paymentDetails[4].data }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-0" width="200px">Total</td>
                      <td>
                        {{ numberToCurrency(remainingPayment) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
          <v-col cols="0" sm="4"> </v-col>
        </v-row>
      </v-col>

      <v-col sm="12">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10" sm="10" md="8">
            <template
              v-if="
                reservationStatus == 'received' ||
                reservationStatus == 'concluded'
              "
            >
              <h2>Contrato</h2>
              <br />
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="py-2 px-0" width="200px">
                        Contrato proveedor
                      </td>
                      <td class="pl-sm-16">
                        <Button
                          text="Ver"
                          class="my-3"
                          aria-label="Descargar contrato"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="pt-0 px-0" width="100px" color="descent">
                        <i
                          >Firmado {{ dateFormater(paymentDetails[0].data) }}</i
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <template v-if="reservationStatus == 'canceled'">
              <h2>Motivo de cancelación</h2>
              <br />
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="py-2 px-0" width="200px">
                        Cancelación realizada por:
                      </td>
                      <td class="pl-sm-16">
                        {{ cancelationData.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-0" width="200px">
                        Fecha de cancelación
                      </td>
                      <td class="pl-sm-16">
                        {{ cancelationData.date }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-0" width="200px">
                        Motivo de cancelación
                      </td>
                      <td class="pl-sm-16">
                        {{ cancelationData.reasonCancelation }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-0" width="200px">Comentario</td>
                      <td class="pl-sm-16">
                        {{ cancelationData.comments }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
          <v-col cols="0" sm="4"> </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Footer />
  </v-container>
</template>

<script>
import Navbar from "@/components/Admin/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";
import moment from "moment";
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  components: { Navbar, Button, Footer },
  filters: {
    percent(dec) {
      if (dec <= 1) {
        return dec * 100 + "%";
      }
    },
  },
  data: () => ({
    requestNumber: 12345,
    acceptEvent: true,
    radioGroup: true,
    serviceType: "Decoracion para evento",
    serviceCost: 10000,
    advancePayment: 0.3,
    settlementPayment: 7000,

    // Change status
    // reservationStatus: "received",
    // reservationStatus: "concluded",
    reservationStatus: "canceled",

    classStyle: [
      "text-decoration-underline",
      "text-decoration-underline",
      "",
      "",
      "",
    ],
    clientInfo: [
      {
        name: "userName",
        data: "Nombre usuario",
      },
      { name: "legalName", data: "Nombre 1" },
      {
        name: "email",
        data: "cliente@cliente.com",
      },
      {
        name: "phone_number",
        data: "123 456 7890 1011",
      },
      { name: "event_city", data: "Chihuahua,Chihuahua" },
    ],
    providerInfo: [
      {
        name: "provider_name",
        data: "Nombre del proveedor",
      },
      { name: "service_type", data: "Mesero" },
      { name: "address", data: "Ojinaga 1835" },
      {
        name: "phone_number",
        data: "123 456 789 1011",
      },
      {
        name: "email",
        data: "proveedor@proveedor.com",
      },
    ],
    paymentDetails: [
      {
        name: "firstPaymentDate",
        data: 1631494873000,
      },
      {
        name: "firstCardDetails",
        data: "VISA****1234",
      },
      {
        name: "paymentDueDate",
        data: 1633911951000,
      },
      {
        name: "liquidationPaymentDate",
        data: 1633494873000,
      },
      {
        name: "liquidationCardDetails",
        data: "VISA****1235",
      },
    ],
    cancelationData: {
      name: "Nombre Cancelacion",
      date: "00/00/0000",
      reasonCancelation: "Cambio de ubicación",
      comments:
        "Est felis viverra elementum tortor tortor fermentum amet, lectus aliquet. Praesent hendrerit sed amet tortor. Tempus id ultrices commodo tincidunt diam tellus. Diam suspendisse lacus sed molestie.",
    },
  }),
  computed: {
    totalAdvance: function () {
      if (this.serviceCost.value !== 0) {
        return parseFloat(this.serviceCost * this.advancePayment).toFixed(2);
      } else {
        return "0.00";
      }
    },
    remainingPayment: function () {
      if (this.serviceCost !== 0) {
        return parseFloat(
          parseFloat(this.serviceCost) - this.settlementPayment
        ).toFixed(2);
      } else {
        return "0.00";
      }
    },
  },
  methods: {
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
    dateFormater(unixTime) {
      return moment(unixTime).format("DD/MM/yyyy");
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
</style>
